import { LogoMain } from "@havan/paladinos-react-icons";
import { FormLogin } from "./login-form";
import styles from "./hero.module.css";

export function Hero() {
  return (
    <section id="inicio" className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.description}>
          <LogoMain />
          <div className={styles.info}>
            <div>
              <p>PAGUE SUA DÍVIDA EM DEZEMBRO</p>
              <p>COM DESCONTO DE ATÉ</p>
            </div>
            <p className={styles.box}>95%</p>
            <p>E GARANTA UM CRÉDITO NOVO</p>
          </div>
          <p>
            *Oferta disponível conforme a condição do credor, condicionada à
            atualização de cadastro no crediário da loja física e quitação total
            à vista
          </p>
        </div>

        <FormLogin />
      </div>
    </section>
  );
}
